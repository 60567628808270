import { GenericService } from '@/services/api/generic.service';
import { SurveyResult } from '@/custom/menicon/models';

export class MeniconSurveyResultsService extends GenericService<SurveyResult> {
  endpoint = '';

  constructor(patientId: string) {
    super();
    this.endpoint = `menicon/v1/patients/${patientId}/virtual-doctor/survey-results`;
  }
}
